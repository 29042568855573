import {
  GET_COMING_PURCHASES_REQUEST,
  GET_COMING_PURCHASES_SUCCESS,
  GET_COMING_PURCHASES_FAILURE
} from '../actions/actionTypes'

const initialState = {
  list: [],
  loading: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMING_PURCHASES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_COMING_PURCHASES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      }

    case GET_COMING_PURCHASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state
  }
}
