import {
  GET_GIFT_LIST_REQUEST,
  GET_GIFT_LIST_SUCCESS,
  GET_GIFT_LIST_FAILURE,
  GET_GIFT_BY_ID_REQUEST,
  GET_GIFT_BY_ID_SUCCESS,
  GET_GIFT_BY_ID_FAILURE,
  SET_TOTAL,
  EXPORT_TABLE_REQUEST,
  EXPORT_TABLE_SUCCESS,
  EXPORT_TABLE_FAILURE
} from 'store/actions/actionTypes'

const initialState = {
  giftList: [],
  selectedGift: null,
  total: 0,
  loadState: {
    pending: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GIFT_LIST_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_GIFT_LIST_SUCCESS:
      return { ...state, giftList: action.list, loadState: { pending: false, error: '' } }

    case GET_GIFT_LIST_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case SET_TOTAL:
      return { ...state, total: action.total }

    case GET_GIFT_BY_ID_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case GET_GIFT_BY_ID_SUCCESS:
      return { ...state, selectedGift: action.gift, loadState: { pending: false, error: '' } }

    case GET_GIFT_BY_ID_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case EXPORT_TABLE_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case EXPORT_TABLE_SUCCESS:
      return { ...state, loadState: { pending: false, error: '' } }

    case EXPORT_TABLE_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    default:
      return state
  }
}
