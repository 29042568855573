import {

} from 'store/actions/actionTypes'

const initialState = { load: false }

export default (state = initialState, action) => {
  switch (action.type) {
    case '':
      return Object.assign({}, state, { load: true })

    default:
      return state
  }
}
