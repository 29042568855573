import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE
} from 'store/actions/actionTypes'

const initialState = {
  user: null,
  loadState: {
    pending: false,
    error: ''
  }
}

export default (state = {
  ...initialState,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case LOGIN_SUCCESS:
      return { ...state, user: action.user, loadState: { pending: false, error: '' } }

    case LOGIN_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case LOGOUT_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case LOGOUT_SUCCESS:
      return { ...state, user: null, loadState: { pending: false, error: '' } }

    case LOGOUT_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    default:
      return state
  }
}
