import * as types from 'store/actions/actionTypes'

const initialState = {
  info: null,
  generateNew: true,
  loadState: {
    pending: false,
    error: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GENERATE_DASHBOARD_INFO_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case types.GENERATE_DASHBOARD_INFO_SUCCESS:
      return { ...state, generateNew: false, loadState: { pending: false, error: '' } }

    case types.GENERATE_DASHBOARD_INFO_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case types.GET_DASHBOARD_INFO_REQUEST:
      return { ...state, loadState: { pending: true, error: '' } }

    case types.GET_DASHBOARD_INFO_SUCCESS:
      return { ...state, info: action.data, loadState: { pending: false, error: '' } }

    case types.GET_DASHBOARD_INFO_FAILURE:
      return { ...state, loadState: { pending: false, error: action.message } }

    case types.FETCH_WITHDRAWALS_CSV_FAILURE:
      return { ...state, loadState: { pending: false, error: action.payload } }

    case types.FETCH_TRANSFERS_CSV_FAILURE:
      return { ...state, loadState: { pending: false, error: action.payload } }

    default:
      return state
  }
}
