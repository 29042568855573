import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

export default function databind (Component) {
  const selectState = Component.databind
  const name = Component.displayName || Component.name || 'Component'

  if (typeof selectState !== 'function') {
    throw new Error(`${name} does not have static databind () method`)
  }

  let actions = Component.actions
  if (actions) {
    actions = (dispatch) => {
      const props = bindActionCreators(Component.actions, dispatch)
      props.dispatch = dispatch
      return props
    }
  }

  return connect(selectState, actions)(Component)
}
